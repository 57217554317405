import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import { Card, Col, Image, Row } from "react-bootstrap";
import React, { useState } from "react";

import { AboutText } from "../1_About/About.resources";
import { BsArrowRightSquare } from "react-icons/bs";
import Button from "../../components/Button/Button";
import Carousel from "../../components/Carousel/Carousel";
import { ContactText } from "../3_Contact/Contact.resources";
import Container from "../../components/Container/Container";
import { ExperiencesText } from "../4_Experiences/Experiences.resources";
import Img1 from "../../assets/img/Portrait/DSC_0763.jpg";
import Img2 from "../../assets/img/Portrait/DSC_0739.jpg";
import Img3 from "../../assets/img/GreyImgs/recenzije.jpg";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Paragraph from "../../components/Paragraph/Paragraph";
import ReactCardFlip from "react-card-flip";
import { RecensionsText } from "../5_Recensions/Recensions.resources";
import aboutMeImg from "../../assets/img/GreyImgs/o meni.jpg";
import aboutMeImg2 from "../../assets/img/Portrait/IMG_8056.jpg";
import img from "../../assets/img/GreyImgs/KONTAKT.jpg";
import { paths } from "../../App/AppRouter/AppRouter.resources";
import styled from "styled-components";
import { text } from "./Home.resources";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)`
  background-color: whitesmoke;
  max-width: 300px;
  height: 480px;
  border-radius: 15px;
  border-width: 15px;
  border-color: whitesmoke;

  :hover {
    cursor: pointer;
  }
`;

const StyledCardFlipped = styled(Card)`
  background-color: whitesmoke;
  max-width: 300px;
  height: 100%;
  border-radius: 15px;
  border-width: 15px;
  border-color: whitesmoke;

  :hover {
    cursor: pointer;
  }
`;

const StyledImg = styled(Card.Img)`
  height: 320px;
  max-width: 300px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

const StyledForwardLink = styled.a`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0;

  :hover {
    color: ${(props) => props.theme.dullColor};
    cursor: pointer;
  }
`;

const StyledCardRecencion = styled(Card)`
  background-color: whitesmoke;
  max-width: 300px;
  height: 550px;
  border-radius: 15px;
  border-width: 15px;
  border-color: whitesmoke;
`;

const StyledImgRecencion = styled(Card.Img)`
  height: 320px;
  max-width: 300px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

const StyledLink = styled.a`
  color: black !important;
`;

export default function Home() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(paths.omeni);
  };

  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<number | undefined>();

  function handleChange(flip: boolean, key: number) {
    setIsFlipped(flip);
    setSelectedKey(key);
  }

  const handleClickRecension = (id: string) => {
    navigate(paths.recenzijeDetaljno, { state: { id: id } });
  };

  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="fluid">
        <React.Fragment>
          <Row className={"px-0 mx-0"}>
            <Carousel />
          </Row>
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{text.aboutMe}</h1>
          </Row>
          <Row className={"px-0 mx-0 pb-4 text-center align-items-center"}>
            <Col
              xs={12}
              md={3}
              className={"px-0 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "600px",
                }}
                src={aboutMeImg}
              />
            </Col>
            <Col xs={12} md={6} className="py-4">
              {text.aboutMeDescription()}
              <Button
                text={text.aboutMeButton}
                onClick={() => handleClick()}
                className={"my-4 p-4"}
                style={{ textTransform: "uppercase" }}
              />
            </Col>
            <Col
              xs={12}
              md={3}
              className={"px-0 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "600px",
                }}
                src={aboutMeImg2}
              />
            </Col>
          </Row>
          {/* O meni */}
          <hr />
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{AboutText.aboutMe}</h1>
          </Row>
          <Row className={"px-0 mx-0 pb-4  text-center align-items-center"}>
            <Col xs={12} md={12} className="py-2">
              {AboutText.aboutMeDescription()}
            </Col>
            <Col
              xs={12}
              md={4}
              className={"p-2 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "650px",
                }}
                src={Img3}
              />
            </Col>
            <Col
              xs={12}
              md={4}
              className={"p-2 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "650px",
                }}
                src={Img2}
              />
            </Col>
            <Col
              xs={12}
              md={4}
              className={"p-2 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "650px",
                }}
                src={Img1}
              />
            </Col>
          </Row>
          {/* Iskustva */}
          <hr />
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{ExperiencesText.title}</h1>
          </Row>
          <Row className="px-0 mx-0 my-0 py-4  align-items-center justify-content-center">
            {ExperiencesText.recensions.map((item, key) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={"key-" + key}
                className="mb-5 align-items-center"
                style={{ justifyContent: "center" }}
              >
                <ReactCardFlip
                  isFlipped={selectedKey === key ? isFlipped : false}
                  flipDirection="vertical"
                >
                  <div
                    style={{ display: "table", margin: "0 auto" }}
                    onClick={() => handleChange(!isFlipped, key)}
                  >
                    <StyledCard>
                      <StyledImg variant="top" src={item.image} />
                      <Card.Body>
                        <Card.Title>{item.name}</Card.Title>
                        <Card.Text>
                          {item.shortText} <BsArrowRightSquare />
                        </Card.Text>
                      </Card.Body>
                    </StyledCard>
                  </div>
                  <div
                    style={{ display: "table", margin: "0 auto" }}
                    onClick={() => handleChange(!isFlipped, key)}
                  >
                    <StyledCardFlipped>
                      <Card.Body>
                        <Card.Text>
                          {item.text} <BsArrowRightSquare />
                        </Card.Text>
                      </Card.Body>
                    </StyledCardFlipped>
                  </div>
                </ReactCardFlip>
              </Col>
            ))}
          </Row>
          {/* Recenzije */}
          <hr />
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{RecensionsText.title}</h1>
          </Row>
          <Row className="px-0 mx-0 my-0 py-4 align-items-center">
            {RecensionsText.recensions.map((item, key) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={"key-" + key}
                className="mb-5 align-items-center "
              >
                <StyledCardRecencion
                  style={{ display: "table", margin: "0 auto" }}
                >
                  <StyledImgRecencion variant="top" src={item.image} />
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>
                      {item.text}{" "}
                      <StyledForwardLink
                        onClick={() => handleClickRecension(item.id)}
                      >
                        <BsArrowRightSquare />
                      </StyledForwardLink>
                    </Card.Text>
                  </Card.Body>
                </StyledCardRecencion>
              </Col>
            ))}
          </Row>
          {/* Kontakt  */}
          <hr />
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{ContactText.title}</h1>
            <h3>{ContactText.description()}</h3>
          </Row>
          <Row
            className={
              "px-0 mt-0 mb-4 py-2 px-0 mx-0 my-0  text-center align-items-center"
            }
          >
            <Col xs={12} md={12} className="py-2 ">
              <Paragraph>
                <React.Fragment>
                  <AiOutlineMail size={"1.3rem"} /> {ContactText.email}
                </React.Fragment>
              </Paragraph>
              <Paragraph>
                <React.Fragment>
                  <AiOutlineInstagram size={"1.3rem"} />{" "}
                  <StyledLink
                    href="https://www.instagram.com/njegaiti"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ContactText.insta}
                  </StyledLink>
                </React.Fragment>
              </Paragraph>
              <Paragraph>
                <React.Fragment>
                  <AiOutlineFacebook size={"1.3rem"} />{" "}
                  <StyledLink
                    href="https://www.facebook.com/njegaitibybenjamin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ContactText.facebook}
                  </StyledLink>
                </React.Fragment>
              </Paragraph>
            </Col>
            <Col
              xs={12}
              md={12}
              className={"px-0 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "800px",
                }}
                src={img}
              />
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    </React.Fragment>
  );
}
