import { Nav, NavDropdown, Navbar } from "react-bootstrap";

import styled from "styled-components";

export const Styles = styled.div`
  .navbar {
    background-color: ${(props) => props.theme.anthracitColor};
  }

  .navbar-bra,
  .navbar-nav .nav-link {
    color: white;

    &:hover {
      color: ${(props) => props.theme.dullColor};
    }
  }
`;

export const LogoDiv = styled.div`
  padding: 0;
  margin: 0;
`;

export const Logo = styled.img`
  width: 150px;
  height: 80px;
  margin: 1px;
`;

export const HiddenLogo = styled(Navbar.Brand)`
  visibility: hidden;
  @media screen and (max-width: ${(props) => props.theme.sm}) {
    display: none;
  }
`;

export const StyledNavDropdown = styled(NavDropdown)`
  @media screen and (max-width: ${(props) => props.theme.sm}) {
    display: none;
  }
`;

export const MobileNavLink = styled(Nav.Link)`
  @media screen and (min-width: ${(props) => props.theme.sm}) {
    display: none;
  }
`;

export const DesktopNavLink = styled(Nav.Link)`
  @media screen and (max-width: ${(props) => props.theme.sm}) {
    display: none;
  }
`;
