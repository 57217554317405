import aidaismajilbegovic from "../../assets/img/Iskustva/33FE9334-619F-4856-97DD-DF714AE89136.jpeg";
import aidazulic from "../../assets/img/Iskustva/IMG_6753.jpg";
import ajlamedic from "../../assets/img/Iskustva/IMG_6754.jpg";
import amelamujkic from "../../assets/img/Iskustva/IMG_6755.jpg";
import ivonafisic from "../../assets/img/Iskustva/8278a7fd-0788-4d4d-b88e-1e80433475fe.jpeg";
import medihabaruscija from "../../assets/img/Iskustva/DF33BDBF-9D69-46B8-A2E0-CB66178704CC.jpeg";
import merimadzukic from "../../assets/img/Iskustva/0505EFE6-0468-429E-A93C-E1EF46E8BA68.jpg";
import merimaljic from "../../assets/img/Iskustva/IMG_6017.jpeg";
import mirnazeko from "../../assets/img/Iskustva/F05AE2C0-83E3-431E-ABCF-FFD6D92A0117.jpeg";

export const ExperiencesText = {
  title: "ISKUSTVA",
  recensions: [
    {
      name: "Merima Aljić, Živinice, BiH",
      shortText:
        "Nakon brojnih terapija, lijekova, preparata i krema, napokon...",
      text: "Nakon brojnih terapija, lijekova, preparata i krema, napokon sam pronašla nekoga ko je znao prepoznati suštinu mog problema i dati sve od sebe da taj problem na adekvatan način i riješi. Hvala Benjamine!",
      image: merimaljic,
    },
    {
      name: "Ivona Fišić, Novi Travnik, BiH",
      shortText: "Za suradnju sa Benjaminom imam samo rijeci hvale ...",
      text: "Za suradnju sa Benjaminom imam samo rijeci hvale, dobila sam puno više od očekivanog. Promjenom rutine i korištenjem proizvoda koje mi je on preporučio moja koža postala je sjajna, nahranjena i zdrava. Jednom kad otkrijete njegaiti vaš život se promijeni. :))",
      image: ivonafisic,
    },
    {
      name: "Mirna Zeko, Njemačka",
      shortText: "Godinama sam se borila s problematičnim licem, sve dok ...",
      text: "Godinama sam se borila s problematičnim licem, sve dok nisam angažirala Benjamina - jedini stručnu osobu koja mi je pomogla vratiti osmijeh na lice i samopouzdanje. Nakon mjesec dana sam vidjela prve pomake i poboljšano stanje. Uz znanje, vrlo mi je važan pristup osobe, a Benjamin je bio pun optimizma i suosjećajan prema mome stanju kože. Moje lice je sada sjajno, bez proširenih pora i akni, a s jednakom rutinom nastavljam i dalje no ovoga puta sa ogromnom dozom samouvjerenosti i odvažnosti odlazaka u javnost bez šminke.",
      image: mirnazeko,
    },
    {
      name: "Mediha Baruščija, Sarajevo, BiH",
      shortText: "Nikada ovako smjelo i ovako blizu nisam smjela napraviti ...",
      text: "Nikada ovako smjelo i ovako blizu nisam smjela napraviti fotografiju svog lica,ali zahvaljujući profilu njegaiti i mom Benjaminu, slobodno mogu reci moj Benjamin, jer on stvarno jeste moj spas koji se desio u zadnji čas mog nervnog sloma. Uz izcrpne konsultacije uz svo moje ne znanje o istom, dolazimo do nemogućih rezultata. Benjo hvala ti na strpljenju, znanju i prije svega hvala ti na ljudskosti. I love you!",
      image: medihabaruscija,
    },
    {
      name: "Aida Ismajilbegović, Sarajevo, BiH",
      shortText: "Prekrasna suradnja koja se pretvorila u prijateljstvo ...",
      text: "Prekrasna suradnja koja se pretvorila u prijateljstvo! Od prvoga dana konzultacija potpuna posvećenost i dostupnost za sva pitanja i nedoumice. Veliki stručnjak pun razumijevanja, podrške i ljubavi.",
      image: aidaismajilbegovic,
    },
    {
      name: "Merima Đukić Savić, Bar, Crna Gora",
      shortText: "Kvalitetno, edukativno i friendly savjetovanje ...",
      text: "Kvalitetno, edukativno i friendly savjetovanje. Plemenita, edukovana i divna duša. Profesionalac u svojoj struci. To je naš Benjamin",
      image: merimadzukic,
    },
    {
      name: "Amela Mujkic, Tuzla, BiH",
      shortText: "Dugogodišnja borba sa crvenilom i raznim dijagnozama ...",
      text: "Dugogodišnja borba sa crvenilom i raznim dijagnozama. Uz adekvatnu rutinu i tretmane ja svoje lice svako jutro u ogledalo pogledam i kažem samo: “Hvala ti Benjo”. Svaka riječ je suvišna da kažem o tebi a jedna je najbitnija: veliki si drug i čovjek.",
      image: amelamujkic,
    },
    {
      name: "Aida Zulić, Njemačka",
      shortText: "Benjamine ti si jedan veliki car ...",
      text: "Benjamine ti si jedan veliki car! Hvala ti za sve sto si ucinio za mene.Godinama sam se borila i sramotila svojih ruku,ali sad je tome kraj,zahvaljujuci tebi.Prvi put u zivotu imam neurodermitis pod kontrolom ,to jeste uopste ga nemam vise ,koristeci tvoju terapiju.",
      image: aidazulic,
    },
    {
      name: "Ajla Međić, Tuzla, BiH",
      shortText: "Dugi period sam se borila sa problematičnom kožom lica ...",
      text: "Dugi period sam se borila sa problematičnom kožom lica, sve dok nisam pronašla nekoga ko je tačno znao šta je mojoj koži potrebno. A to je Benjamin. Promjenom moje stare rutine koža je dobila novi sjaj i svakim danom je bolja i bolja. Benjo hvala ti na svemu i na nesebičnom dijeljenju svog znanja koje dovodi do savršenih rezultata.",
      image: ajlamedic,
    },
  ],
};
