import { Container as BSContainer, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import Container from "../../components/Container/Container";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Paragraph from "../../components/Paragraph/Paragraph";
import { RecensionsText } from "../5_Recensions/Recensions.resources";
import { useLocation } from "react-router-dom";

export default function RecensionDetails() {
  const location = useLocation();
  interface RecensionDetails {
    id: string | null;
  }
  const record = location.state as RecensionDetails;
  const [recensionId, setRecensionId] = useState<string | undefined>();

  useEffect(() => {
    var selectedId: string | undefined;
    if (record && record.id) {
      for (var i = 0; i < RecensionsText.recensions.length; i++) {
        if (RecensionsText.recensions[i].id === record.id) {
          selectedId = record.id;
        }
      }
    }

    setRecensionId(selectedId);
  }, [recensionId, record]);

  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="p-4">
        <React.Fragment>
          <BSContainer>
            {recensionId ? (
              <React.Fragment>
                {RecensionsText.recensions.map((item, key) => (
                  <React.Fragment key={"id-" + key}>
                    {item.id === record.id && item.content()}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Paragraph>
                  <>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    {"  "}U slucaju da Vasa recenzija nije pronadjena, molimo
                    Vas vratite se na predhodnu stranicu.
                  </>
                </Paragraph>
              </React.Fragment>
            )}
          </BSContainer>
        </React.Fragment>
      </Container>
    </React.Fragment>
  );
}
