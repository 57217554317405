import React from "react";

export const ContactText = {
  title: "KONTAKT",
  email: "njegaiti@gmail.com",
  insta: "@njegaiti",
  facebook: "Njegaiti by B",
  description: () => (
    <React.Fragment>
      <p>
        Budi slobodna ili slobodan i kontaktiraj me da zajedno riješimo problem
        ili ispunimo tvoju želju za boljom i zdravijom kožom lica i tijela.
      </p>
    </React.Fragment>
  ),
};
