import { Image } from "react-bootstrap";
import Paragraph from "../Paragraph/Paragraph";
import React from "react";
import img1 from "../../assets/img/Recenzije/13042022_rilastil/IMG_1432.jpeg";
import img2 from "../../assets/img/Recenzije/13042022_rilastil/IMG_1439.jpeg";
import img3 from "../../assets/img/Recenzije/13042022_rilastil/IMG_1443.jpeg";

export default function DetailsRilastil13042022() {
  return (
    <React.Fragment>
      <Paragraph>
        U današnjoj recenziji donosim Vam jedan od savršenih seruma za apsolutno
        sve tipove kože lica.
      </Paragraph>
      <Paragraph>
        Koliko puta ste bili u dilemi koji serum da kupite a djelovati će i anti
        age, i na hidrataciju, da nije ljepljive teksture a da ne izaziva
        alergijske reakcije?
      </Paragraph>
      <Paragraph>E evo nam jednog takvog. </Paragraph>
      <h3>RILASTIL MULTIREPAIR H.A. DETOX SERUM</h3>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img1}
      />
      <Paragraph>
        Rilastil Multirepair linija je proizvoda namijenjenih tankoj i umornoj
        koži koja pokazuje znakove preranog starenja uslijed oksidacijskog
        stresa. Stalna izloženost čimbenicima okoline poput UV zraka, smoga,
        onečišćenja i lošeg načina života (alkohol, pušenje, stres, loša
        prehrana …) dodatno ubrzava prirodni proces starenja. Smog i zagađivači
        okoliša povećavaju stvaranje staračkih pjega i bora za čak 20% *
      </Paragraph>
      <Paragraph>
        Izvor: * Vierkötter A., Schikowski T, Ranft U., Sugiri D., Matsui M.,
        Krämer U., et al. Izloženost česticama u zraku i vanjsko starenje kože.
        J Invest Dermatol 2010; 130: 2719-26 Rilastil Multirepair H.A. je serum
        svilenkaste, brzo upijajuće teksture koji pomaže vratiti koži optimalnu
        razinu vlage, potiče stanični metabolizam, a time i proces u kojem naša
        koža stvara nove stanice koje igraju veliku ulogu u izgledu naše kože i
        kako ona izgleda. Uz to, pomaže u detoksikaciji kože štiteći je od
        slobodnih radikala (antioksidativno djelovanje *) i okolišnih čimbenika
        (anti-onečišćenje *) poput plavog svjetla, smoga i onečišćenja. Serum
        djeluje protiv sivih nijansi vitke kože, pomaže popunjavanju brazda na
        licu i poboljšava čvrstoću kože. Pogodan je za sve tipove kože.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img2}
      />
      <Paragraph>
        U kliničkoj studiji Rilastil Multirepair H.A. Detoksikacija seruma, kod
        17 dobrovoljaca, prosječne dobi 54 godine (42-67 godina) sa suhom i vrlo
        suhom kožom, u roku od 28 dana (2x dnevno), broj bora smanjio se za
        13,8%, površina bora za 33,3% a duljina bora za 31,3%. * In vitro
        testovi pokazali su da proizvod štiti od oksidativnog stresa, protiv
        onečišćenja i plavog svjetla.
      </Paragraph>
      <Paragraph>AKTIVNE TVARI:</Paragraph>
      <Paragraph>
        · H.A. složeni 2% - 3 različita oblika hijaluronske kiseline koji
        obnavljaju optimalnu razinu vlažnosti kože
      </Paragraph>
      <Paragraph>
        - Hijaluronska kiselina velike molekularne težine (natrijev hijaluronat)
        - hidratantna površina, tvori zaštitni sloj koji sprječava
        transepidermalni gubitak vode (TEWL)
      </Paragraph>
      <Paragraph>
        - Hijaluronska kiselina niske molekularne težine (hidrolizirana
        hijaluronska kiselina) - duboko vlaži, vraća elastičnost
      </Paragraph>
      <Paragraph>
        - Natrijev butiroil hijaluronat - noviji oblik koji omogućuje dugotrajnu
        hidrataciju i poboljšava elastičnost kože
      </Paragraph>
      <Paragraph>
        <>
          · DETOXIOX - aktivna tvar posljednje generacije pronađena u ekstraktu
          španjolske masline, koji sadrži puno hidroksitirosol ({">"} 20%),
          tirozol i polifenole. Smanjuje oštećenja kože uzrokovana slobodnim
          radikalima i sprječava stvaranje novih, potiče sintezu kolagena tipa 1
          i umiruje kožu.
        </>
      </Paragraph>
      <Paragraph>
        · Mandelinska kiselina 4% - jedna od AHA kiselina, koja je također
        pogodna za osjetljivu kožu. Potiče piling kože (piling), regeneraciju
        kože i sintezu kolagena, elastina i gluksaminoglikana.
      </Paragraph>
      <Paragraph>
        NE SADRŽI DERIVATE PŠENICE - NEKOMEDOGENI - HIPOALERGENI (Sastav
        proizvoda minimalizira mogućnost alergija.) - KLINIČKO TESTIRANO -
        TESTIRANO NA NIKELU, KOBALTU, KROMU, PALADIJU I ŽIVI * * Manje od 1 ppm.
        Male količine mogu uzrokovati povećanu osjetljivost kože.
      </Paragraph>
      <Paragraph>
        UPOTREBA: Nanesite ujutro i navečer na čistu i suhu kožu lica prije
        Kreme za hidrataciju ili ujutro prije SPF-A. zbjegavajte područje oko
        očiju.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img3}
      />
    </React.Fragment>
  );
}
