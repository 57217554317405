import { Card, Col, Row } from "react-bootstrap";

import { BsArrowRightSquare } from "react-icons/bs";
import Container from "../../components/Container/Container";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import React from "react";
import { RecensionsText } from "./Recensions.resources";
import { paths } from "../../App/AppRouter/AppRouter.resources";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)`
  background-color: whitesmoke;
  max-width: 300px;
  height: 550px;
  border-radius: 15px;
  border-width: 15px;
  border-color: whitesmoke;
`;

const StyledImg = styled(Card.Img)`
  height: 320px;
  max-width: 300px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

const StyledForwardLink = styled.a`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0;

  :hover {
    color: ${(props) => props.theme.dullColor};
    cursor: pointer;
  }
`;

export default function Recensions() {
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    navigate(paths.recenzijeDetaljno, { state: { id: id } });
  };

  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="p-4">
        <React.Fragment>
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{RecensionsText.title}</h1>
          </Row>
          <Row className="p-2 align-items-center">
            {RecensionsText.recensions.map((item, key) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={"key-" + key}
                className="mb-5 align-items-center"
              >
                <StyledCard style={{ display: "table", margin: "0 auto" }}>
                  <StyledImg variant="top" src={item.image} />
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>
                      {item.text}{" "}
                      <StyledForwardLink onClick={() => handleClick(item.id)}>
                        <BsArrowRightSquare />
                      </StyledForwardLink>
                    </Card.Text>
                  </Card.Body>
                </StyledCard>
              </Col>
            ))}
          </Row>
        </React.Fragment>
      </Container>
    </React.Fragment>
  );
}
