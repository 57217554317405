import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import { Col, Container, Row } from "react-bootstrap";

import { BiAlignLeft } from "react-icons/bi";
import { FooterText } from "./Footer.resources";
import React from "react";
import logo from "../../assets/img/logo2.png";
import { paths } from "../../App/AppRouter/AppRouter.resources";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StyledFooter = styled.footer`
  width: 100%;
  line-height: 60px;
  background-color: ${(props) => props.theme.anthracitColor} !important;
  padding: 1rem;
`;

const Paragraph = styled.p`
  margin-bottom: 0;
  color: whitesmoke;
  text-align: left;
`;

const StyledLink = styled.a`
  color: whitesmoke !important;
`;

const StyledContainer = styled(Container)`
  background-color: ${(props) => props.theme.anthracitColor} !important;
`;

export const Logo = styled.img`
  width: 150px;
  height: 80px;
  margin: 1px;
`;

export default function Footer() {
  const navigate = useNavigate();

  const handleImpressum = () => {
    navigate(paths.impressum);
  };

  return (
    <StyledFooter>
      <StyledContainer>
        <Row>
          <Col xs={12} md={6}>
            <div style={{ marginBottom: "3rem", marginTop: "1rem" }}>
              <Logo src={logo} alt="Logo" />
            </div>
            <div
              style={{ position: "absolute", bottom: "0px", marginTop: "2rem" }}
            >
              <Paragraph>{FooterText.copyright}</Paragraph>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <Paragraph style={{ textTransform: "uppercase" }}>
              <b>{FooterText.kontakt}</b>
            </Paragraph>
            <Paragraph>
              <AiOutlineMail size={"1.3rem"} /> {FooterText.email}
            </Paragraph>
            <Paragraph>
              <AiOutlineInstagram size={"1.3rem"} />{" "}
              <StyledLink
                href="https://www.instagram.com/njegaiti"
                target="_blank"
                rel="noreferrer"
              >
                {FooterText.insta}
              </StyledLink>
            </Paragraph>
            <Paragraph>
              <AiOutlineFacebook size={"1.3rem"} />{" "}
              <StyledLink
                href="https://www.facebook.com/njegaitibybenjamin"
                target="_blank"
                rel="noreferrer"
              >
                {FooterText.facebook}
              </StyledLink>
            </Paragraph>
            <Paragraph>
              <BiAlignLeft size={"1.3rem"} />{" "}
              <StyledLink
                href=""
                onClick={() => handleImpressum()}
                // target="_blank"
                rel="noreferrer"
                style={{ textTransform: "uppercase" }}
              >
                {FooterText.impressum}
              </StyledLink>
            </Paragraph>
          </Col>
        </Row>
      </StyledContainer>
    </StyledFooter>
  );
}
