import { Image } from "react-bootstrap";
import Paragraph from "../Paragraph/Paragraph";
import React from "react";
import img1 from "../../assets/img/Recenzije/13042022_dermedichydrain3/IMG_0896.jpeg";
import img2 from "../../assets/img/Recenzije/13042022_dermedichydrain3/IMG_0899.jpeg";
import img3 from "../../assets/img/Recenzije/13042022_dermedichydrain3/IMG_0915.jpeg";

export default function DetailsOdermedichydrain3() {
  return (
    <React.Fragment>
      <Paragraph>
        O Dermedic Hydrain3 puteru za tijelo ne treba puno pričati.
      </Paragraph>
      <Paragraph>
        Samo ga kupite I probajte, ali kada ga budete nanosili na kožu brojite
        do 4 I cijela koža postaje mat. Ono što je nejgova prednost jeste I sama
        tekstura koja nije teška, spriječava urastanje dlaka I najbitnije suhoću
        kože tijela.
      </Paragraph>
      <Paragraph>Bilo koji tip kože tijela može isti da koristi.</Paragraph>
      <Paragraph>Šta nam Nudi ovaj puter?</Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img1}
      />
      <Paragraph>
        Intenzivna hidratacija i zaštitna njega za veoma suhu, grubu i ljuskavu
        kožu. Pruža neposredno olakšanje i smanjuje osjećaj zatezanja.
      </Paragraph>
      <Paragraph>
        Trosmjerno djelovanje hidratizirajućih i njegujućih aktivnih sastojaka
        uključuje duboku i dugotrajnu hidrataciju kože (inovativni Aqualare
        kompleks, u očekivanju patentiranja, koji sadrži 4 oblika hijaluronske
        kiseline), njegu i regeneraciju, kao i obnavljanje pravilnog rada
        vlastite zaštitne barijere.
      </Paragraph>
      <Paragraph>Proizvod spriječava pucanje i keratozu epiderme. </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img2}
      />
      <Paragraph>
        Pakovan je u pakiranju u težini od 225ml u teglici. Vjerujte mi da kroz
        samo prvo korištenje prvo što će Vas kupiti mirisom, drugo svojom
        funkcionalnošću I prihvaljivom cijenom.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img3}
      />
    </React.Fragment>
  );
}
