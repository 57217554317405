import { Container as BSContainer, Col, Image, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

import { AboutText } from "./About.resources";
import Container from "../../components/Container/Container";
import Img1 from "../../assets/img/Portrait/DSC_0763.jpg";
import Img2 from "../../assets/img/Portrait/DSC_0739.jpg";
import Img3 from "../../assets/img/GreyImgs/recenzije.jpg";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import React from "react";
import { zoomInUp } from "react-animations";

const zoomUp = keyframes`${zoomInUp}`;

const StyledCol = styled(Col)`
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${zoomUp};
  }
`;

export default function About() {
  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="fluid" style={{ height: "100%" }}>
        <BSContainer className="pt-2">
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{AboutText.aboutMe}</h1>
          </Row>
          <Row className={"px-0 mx-0 pb-4  "}>
            <Col xs={12} md={12} className="py-2">
              {AboutText.aboutMeDescription()}
            </Col>
            <StyledCol
              xs={12}
              md={4}
              className={"p-2 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "650px",
                }}
                src={Img3}
              />
            </StyledCol>
            <StyledCol
              xs={12}
              md={4}
              className={"p-2 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "650px",
                }}
                src={Img2}
              />
            </StyledCol>
            <StyledCol
              xs={12}
              md={4}
              className={"p-2 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <Image
                style={{
                  maxWidth: "100%",
                  maxHeight: "650px",
                }}
                src={Img1}
              />
            </StyledCol>
          </Row>
        </BSContainer>
      </Container>
    </React.Fragment>
  );
}
