import { Container as BSContainer, Col, Image, Row } from "react-bootstrap";

import Container from "../../components/Container/Container";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import React from "react";
import img from "../../assets/img/Portrait/BenjoSeptembar2021-168.jpeg";

export default function NotFound() {
  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="fluid">
        <BSContainer>
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <Col xs={12}>
              <h3>Error 404</h3>
              <p>
                <b>Trazena stranica ne postoji.</b>
              </p>
            </Col>
            <Col xs={12}>
              <Image
                src={img}
                style={{ maxHeight: "600px", maxWidth: "100%" }}
              />
            </Col>
          </Row>
        </BSContainer>
      </Container>
    </React.Fragment>
  );
}
