import { Image } from "react-bootstrap";
import Paragraph from "../Paragraph/Paragraph";
import React from "react";
import img1 from "../../assets/img/Recenzije/21042022_origins/ORIGINS-GinZing-Oil-Free-Gel-Moisturizer-30ml-500x500.jpg";
import img2 from "../../assets/img/Recenzije/21042022_origins/ORIGINS-GinZing-Ultra-Hydra-Cream-30ml-500x500.jpg";

export default function DetailsOrigins21042022() {
  return (
    <React.Fragment>
      <Paragraph>
        Buđenje za umornu kožu! – Ova unaprijeđena lagana formula bez ulja u gel
        formulaciji i ultra hidratantna krema sa aktivnim ekstraktima Ginsenga i
        kafe brzo budi i najumorniju kožu, te joj pruža osvježavajuću
        hidrataciju.
      </Paragraph>
      <Paragraph>
        Inovativna *Moisture Locking tehnologija doslovno zaključava vlagu u
        koži, omogućavajući dugotrajniju hidrataciju i do 72h.
      </Paragraph>
      <Paragraph>
        Izgledajte svježe i odmorno! (i bez espresso kafe). Da, da.
      </Paragraph>
      <Paragraph>
        Jedna od meni omiljenih krema za hidrataciju I osvježenje kože lica.
        Pored toga sama priča Originsa kao Brenda inspiraciju budi u svima nama
        da budemo savršeno blistavi I njegovani. Bez obzira koji tip kože lica,
        koja problematika, origins ima odgovor za sve.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img1}
      />
      <Paragraph>Šta je sa sastavom?</Paragraph>
      <Paragraph>Aktivni sastojci:</Paragraph>
      <Paragraph>
        <>
          <b>Kofein:</b> ekstraktovan iz zrna kafe je prirodan anti-iritant koji
          budi kožu i čini je svježom.
        </>
      </Paragraph>
      <Paragraph>
        <>
          <b>Panax Ginseng:</b> Pomaže u protoku energije koz kožu čineći je
          otpornom. Uspostavlja ravnotežu i u najdubljim slojevima kože i
          revitalizira vaše lice.
        </>
      </Paragraph>
      <Paragraph>
        <>
          <b>Moisture Locking tehnologija*:</b>
        </>
      </Paragraph>
      <Paragraph>
        <>
          <b>Japanska srebrna trava –</b> Potiče aktivaciju celularnih proteina
          te duže zadržava vlagu u koži.
        </>
      </Paragraph>
      <Paragraph>
        <>
          <b>Sjemenke suncokreta i ječma –</b> Esencijalne masti koje
          sprječavaju gubitak vlage u koži
        </>
      </Paragraph>
      <Paragraph>
        <>
          <b>
            Spoj esencijalnih ulja grejpa, limuna i Essential Oil Blend of
            Grapefruit, Lemon & mente :
          </b>{" "}
          Osvježava kožu i podiže tonus lica.
        </>
      </Paragraph>

      <Paragraph>
        Ako se odlučite kupiti, masnokošci birajte ‘’ gel oil free’’ verziju’’
        dok ostali slobodno drugu varijantu.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img2}
      />
      <Paragraph>
        P.S. Kada je jednom prbate, vratiti ćete se ponovo obećavam. I da,
        njihova krema za područje oko očiju je sestra blistavosti područja oko
        očiju.
      </Paragraph>
    </React.Fragment>
  );
}
