import Container from "../../components/Container/Container";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import React from "react";

export default function News() {
  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="fluid">
        <div>Detalji info slike itd.</div>
      </Container>
    </React.Fragment>
  );
}
