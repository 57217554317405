import { Carousel as BSCarousel } from "react-bootstrap";
import { CarouselText } from "./Carousel.resource";
import React from "react";
import styled from "styled-components";
import { theme } from "../../style/theme";

const StyledImg = styled.img`
  @media screen and (max-width: ${(props) => props.theme.md}) {
    height: 40vh;
  }

  @media screen and (min-width: ${(props) => props.theme.md}) {
    height: 80vh;
  }
  object-fit: cover;
  width: 100vw;
`;

const StyledCaption = styled(BSCarousel.Caption)`
  /* background-color: rgba(21, 14, 26, 0.35); */
`;

const StyledH2 = styled.h2`
  text-shadow: 2px 2px ${(props) => props.theme.anthracitColor};
  color: ${(props) => props.theme.goldLightColor}; ;
`;

export default function Carousel() {
  return (
    <React.Fragment>
      <BSCarousel
        fade
        style={{
          width: "100%",
          backgroundColor: theme.anthracitColor,
          padding: "0",
        }}
      >
        {CarouselText.map((item, key) => (
          <BSCarousel.Item interval={1500} key={"id-" + key}>
            <StyledImg className="d-block" src={item.image} alt="First slide" />
            <StyledCaption>
              <StyledH2>
                <b>{item.text}</b>
              </StyledH2>
            </StyledCaption>
          </BSCarousel.Item>
        ))}
      </BSCarousel>
    </React.Fragment>
  );
}
