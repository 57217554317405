import Paragraph from "../../components/Paragraph/Paragraph";
import React from "react";

export const AboutText = {
  aboutMe: "O MENI?",
  aboutMeDescription: () => (
    <React.Fragment>
      <Paragraph>
        Ja sam Benjamin Mrkonjić. Alter ego Njega&ti. Samo pokretanje mog
        profila i ove stranice inspirisano je svakodnevnim problemima sa kožom
        mnoštvo ljudi koji se bore i lutaju u svijetu dermatokozmetike.
      </Paragraph>
      <Paragraph>
        Sa svojim višegodišnjim iskustvom rada u apoteci, te studiranjem na
        jednom od fakulteta kroz koji sam naučio razne hemijske reakcije,
        spojeve i aktivne supstance odlučio sam se da moj životni put i ljubav
        bude zapravo ovo sada što čitaš.
      </Paragraph>
      <Paragraph>
        Drugi razlog je taj što sam i sam imao problema sa kožom i želio
        adekvatnu pomoć ali je nisam dobio. U to ime, od budućeg magistra
        dermatofarmacije i kozmetologije i prvog BiH Beauty Advisor-a dobiti
        ćete uvijek iskrene savjete, preporuke, recenzije i konsultacije.
      </Paragraph>
      <Paragraph>
        Ukoliko trebaš pomoć, imaš promjene na koži koje su ti sumnjive, ili
        želiš da ih se riješiš budi slobodna/slobodan da mi se javiš putem box-a
        na mail adresu.
      </Paragraph>
    </React.Fragment>
  ),
};
