import React, { CSSProperties } from "react";

import styled from "styled-components";

interface StyledContainerProps {
  backgroundColor?: string;
}

var StyledContainer = styled.div<StyledContainerProps>`
  margin: 0;
  padding: 0;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.lithiumLowColor};
`;

interface ContainerProps {
  children: React.ReactChild;
  className?: string;
  backgroundColor?: string;
  style?: CSSProperties;
}

export default function Container(props: ContainerProps) {
  return (
    <StyledContainer
      backgroundColor={props.backgroundColor}
      className={props.className}
      style={props.style}
    >
      {props.children}
    </StyledContainer>
  );
}
