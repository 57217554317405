import { Container as BSContainer, Col, Image, Row } from "react-bootstrap";
import { fadeInLeft, fadeInRight, fadeInUp } from "react-animations";
import styled, { keyframes } from "styled-components";

import Button from "../../components/Button/Button";
import { ConsultationText } from "./Consultation.resources";
import Container from "../../components/Container/Container";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import React from "react";
import img from "../../assets/img/GreyImgs/konsultacije.jpg";
import { paths } from "../../App/AppRouter/AppRouter.resources";
import { useNavigate } from "react-router-dom";

const bounceLeft = keyframes`${fadeInLeft}`;
const bounceRight = keyframes`${fadeInRight}`;
const fadeInUpItem = keyframes`${fadeInUp}`;

const BouncyLeftDiv = styled.div`
  text-transform: uppercase;
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${bounceLeft};
  }

  background: rgba(56, 62, 66, 0.15);

  &.bubble {
    position: relative;
    color: black;
    line-height: 40px;
    text-align: center;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    padding: 0px;
  }
  &.bubble:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: rgba(56, 62, 66, 0.15) transparent;
    border-width: 20px 20px 0;
    bottom: -20px;
    left: 50%;
    margin-left: -20px;
  }
`;

const BouncyRightDiv = styled.div`
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${bounceRight};
  }
  background: rgba(18, 97, 43, 0.4);
  text-transform: uppercase;
  &.bubble {
    position: relative;
    color: black;
    line-height: 70px;
    text-align: center;
    width: 100%;
    height: 80px;
    border-radius: 10px;
    padding: 0px;
  }
  &.bubble:after {
    content: "";
    position: absolute;
    display: block;
    width: 0;
    z-index: 1;
    border-style: solid;
    border-color: rgba(18, 97, 43, 0.4) transparent;
    border-width: 20px 20px 0;
    bottom: -20px;
    left: 50%;
    margin-left: -20px;
  }
`;

const BouncyRightDivBottom = styled.div`
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${bounceRight};
  }
  text-transform: uppercase;
  margin-top: 5rem;
  &.circle {
    border-radius: 50%;
    background-color: rgba(56, 62, 66, 0.15);
    color: black;
    width: 12rem;
    height: 12rem;
    text-align: center;
    line-height: 11rem;
  }
`;

const BouncyRightDivTop = styled.div`
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${bounceLeft};
  }
  text-transform: uppercase;
  margin-top: 3rem;
  &.circle {
    border-radius: 50%;
    background-color: rgba(18, 97, 43, 0.4);
    color: black;
    width: 12rem;
    height: 12rem;
    text-align: center;
    line-height: 11rem;
  }
`;

const StyledImg = styled(Image)`
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${fadeInUpItem};
  }
`;

const StyledButton = styled(Button)`
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${fadeInUpItem};
  }
  max-width: 13rem;
`;

export default function Consultation() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(paths.kontakt);
  };

  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="fluid">
        <BSContainer className="pt-2">
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{ConsultationText.title}</h1>
          </Row>
          <Row className={"px-2 mx-0 py-4"}>
            <Col xs={12} lg={4} className="py-2 px-0">
              <Col className="px-0 pb-4" xs={12}>
                <BouncyLeftDiv className="bubble left my-2 ms-auto">
                  <b>{ConsultationText.boxTop()}</b>
                </BouncyLeftDiv>
              </Col>
              <Col className="px-0" xs={12}>
                <BouncyRightDivTop className="circle  ms-auto">
                  <b>{ConsultationText.bubbleTop}</b>
                </BouncyRightDivTop>
              </Col>
            </Col>
            <Col
              xs={12}
              lg={4}
              className={"px-md-4 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <StyledImg
                style={{
                  maxWidth: "100%",
                  maxHeight: "1100px",
                }}
                src={img}
              />
            </Col>
            <Col xs={12} lg={4} className="py-2">
              <Col xs={12}>
                <BouncyRightDiv className="bubble right my-4 ms-auto">
                  <b>{ConsultationText.boxBottom}</b>
                </BouncyRightDiv>
              </Col>
              <Col xs={12}>
                <BouncyRightDivBottom className="circle  ">
                  <b>{ConsultationText.bubbleBottom}</b>
                </BouncyRightDivBottom>
              </Col>
            </Col>
          </Row>
          <Row className="text-center align-items-center">
            <div>
              <StyledButton
                text={ConsultationText.button}
                onClick={() => handleClick()}
                className={"my-4 p-4"}
                style={{ textTransform: "uppercase" }}
              />
            </div>
          </Row>
        </BSContainer>
      </Container>
    </React.Fragment>
  );
}
