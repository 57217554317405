import styled, { ThemeProvider } from "styled-components";

import AppRouter from "./AppRouter/AppRouter";
import React from "react";
import { theme } from "../style/theme";

// * Styled Components
const AppWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: ${(props) => props.theme.lithiumLowColor};
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppWrapper>
        <AppRouter />
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;
