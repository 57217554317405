import { Card, Col, Row } from "react-bootstrap";
import React, { useState } from "react";

import { BsArrowRightSquare } from "react-icons/bs";
import Container from "../../components/Container/Container";
import { ExperiencesText } from "./Experiences.resources";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import ReactCardFlip from "react-card-flip";
import styled from "styled-components";

const StyledCard = styled(Card)`
  background-color: whitesmoke;
  max-width: 300px;
  height: 480px;
  border-radius: 15px;
  border-width: 15px;
  border-color: whitesmoke;

  :hover {
    cursor: pointer;
  }
`;

const StyledCardFlipped = styled(Card)`
  background-color: whitesmoke;
  max-width: 300px;
  height: 100%;
  border-radius: 15px;
  border-width: 15px;
  border-color: whitesmoke;

  :hover {
    cursor: pointer;
  }
`;

const StyledImg = styled(Card.Img)`
  height: 320px;
  max-width: 300px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

export default function Experiences() {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);
  const [selectedKey, setSelectedKey] = useState<number | undefined>();

  function handleChange(flip: boolean, key: number) {
    setIsFlipped(flip);
    setSelectedKey(key);
  }

  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="p-4">
        <React.Fragment>
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{ExperiencesText.title}</h1>
          </Row>
          <Row className="p-2 align-items-center">
            {ExperiencesText.recensions.map((item, key) => (
              <Col
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={"key-" + key}
                className="mb-5 align-items-center"
              >
                <ReactCardFlip
                  isFlipped={selectedKey === key ? isFlipped : false}
                  flipDirection="vertical"
                >
                  <div
                    style={{ display: "table", margin: "0 auto" }}
                    onClick={() => handleChange(!isFlipped, key)}
                  >
                    <StyledCard>
                      <StyledImg variant="top" src={item.image} />
                      <Card.Body>
                        <Card.Title>{item.name}</Card.Title>
                        <Card.Text>
                          {item.shortText} <BsArrowRightSquare />
                        </Card.Text>
                      </Card.Body>
                    </StyledCard>
                  </div>
                  <div
                    style={{ display: "table", margin: "0 auto" }}
                    onClick={() => handleChange(!isFlipped, key)}
                  >
                    <StyledCardFlipped>
                      <Card.Body>
                        <Card.Text>
                          {item.text} <BsArrowRightSquare />
                        </Card.Text>
                      </Card.Body>
                    </StyledCardFlipped>
                  </div>
                </ReactCardFlip>
              </Col>
            ))}
          </Row>
        </React.Fragment>
      </Container>
    </React.Fragment>
  );
}
