import { Image } from "react-bootstrap";
import Paragraph from "../Paragraph/Paragraph";
import React from "react";
import img1 from "../../assets/img/Recenzije/28042022_gorsen/image00001.png";
import img2 from "../../assets/img/Recenzije/28042022_gorsen/image00002.jpeg";
import img3 from "../../assets/img/Recenzije/28042022_gorsen/image00003.jpeg";
import img4 from "../../assets/img/Recenzije/28042022_gorsen/image00004.jpeg";
import img5 from "../../assets/img/Recenzije/28042022_gorsen/image00005.jpeg";
import img6 from "../../assets/img/Recenzije/28042022_gorsen/image00006.jpeg";

export default function DetailsGorsen28042022() {
  return (
    <React.Fragment>
      <h3>Gorsen anti akne krema</h3>
      <Paragraph>
        Drago mi je što sam prvi u BiH progovorio o brendu koji danas zauzima
        90% polica naše skincare rutine. Gorsen proizvodi su sa potpisom prirode
        i dolaze nam iz dragog Mostara. Ali jedne dama u sve te divne proizvode
        je skupila aktivne supstance sa svih krajeva svijeta.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img1}
      />
      <Paragraph>
        Vi znate da ja njihovu anti akne kremu predstavljam kao broj jedan u
        rješavanju svih problema sa aknama i ona kao takva mi je dala tako
        veliko ''HVALA'' i meni ali i Svima Vama.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img2}
      />
      <Paragraph>
        Kako djeluje sama Gorsen Anti akne krema? To sve interesuje pa ćemo u
        današnjoj recenziji i napisati par jednostavnih i korisnih uputa kako i
        za koga.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img3}
      />
      <Paragraph>
        1. Djelovanje anti akne kreme pojačava pravilno nanošenje! Nanesite
        kremu na čistu kožu tako što je umasirate par minuta sve dok ne
        primijetite da koža dobija blago rozu boju uslijed potaknute cirkulacije
        masažom! Potaknuta cirkulacija je provodnik svih kvalitetnih sastojaka
        anti akne kreme do stanica kože!
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img4}
      />
      <Paragraph>
        2. Kako koristiti ANTI-ACNE kremu. ANTI-ACNE kremu je vrlo bitno
        nanijeti na čistu kožu i lagano umasirati kako bi ona što bolje
        prihvatila sve aktivne tvari iz proizvoda. Vrlo je važno naglasiti da u
        prvih 7 dana korištenja proizvoda može doći do pojave novih akni jer
        krema snažno djeluje na sve slojeve kože te tako čisti sve one uporne
        akne i komedone koji bi se trebali pokazati na površini kože nakon
        određenog vremena, a već su formirani ispod kože. Nakon 10 dana
        korištenja ANTI-ACNE kreme primijetit ćete vidno poboljšanje na koži.
        Upalni procesi će se smanjiti i nećete imati nove akne. ANTI-ACNE krema
        se pokazala odlična i kod problema sa uraslim dlačicama te je vrlo brzo
        zamijenila ostale proizvode koji su namijenjeni njezi bikini zone nakon
        depilacije. ANTI-ACNE kremu možete koristiti na svim dijelovima tijela.
      </Paragraph>
      <Paragraph>
        Bitno je naglasiti da sve Gorsen proizvode smiju koristiti apsolutno
        svi: i trudnice i osobe koje imaju problema sa kožom nakon hemoterapija
        jer većina proizvoda ali jedan posebno je bogat betaglukanom koji u
        takvim situacijama je ''SOS'' rješenje za sve probleme. Ali da ne bježim
        od teme..
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img5}
      />
      <Paragraph>
        3. Gorsen krema za regulira lučenje sebuma, dubinski čisti postojeće
        akne, djeluje protiv začepljenih pora i smanjuje vjerojatnost od ponovne
        pojave akni. Formula sadrži organska biljna ulja makadamije, papaje,
        marelice i tamanu, u kombinaciji sa organskim eteričnim uljma čajevca,
        njemačke kamilice i širokolisne lavande.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img6}
      />
    </React.Fragment>
  );
}
