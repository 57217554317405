import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import About from "../../pages/1_About/About";
import Consultation from "../../pages/2_Consultation/Consultation";
import Contact from "../../pages/3_Contact/Contact";
import Details from "../../pages/9_Details/Details";
import Experiences from "../../pages/4_Experiences/Experiences";
import Footer from "../../components/Footer/Footer";
import Hair from "../../pages/7_Hair/Hair";
import Home from "../../pages/0_Home/Home";
import Impressum from "../../pages/10_Impressum/Impressum";
import News from "../../pages/8_News/News";
import NotFound from "../../pages/X_NotFound/NotFound";
import React from "react";
import RecensionDetails from "../../pages/5a_RecensionDetails/RecensionDetails";
import Recensions from "../../pages/5_Recensions/Recensions";
import Skin from "../../pages/6_Skin/Skin";
import { paths } from "./AppRouter.resources";
import styled from "styled-components";

const MainSection = styled.div`
  flex-grow: 1;
`;

export default function AppRouter() {
  return (
    <Router>
      <MainSection>
        <Routes>
          {/* Home Page */}
          <Route path={paths.home} element={<Home />}></Route>
          {/* About Page */}
          <Route path={paths.omeni} element={<About />} />
          {/* Consultation Page  */}
          <Route path={paths.konsultacije} element={<Consultation />} />
          {/* Contact Page  */}
          <Route path={paths.kontakt} element={<Contact />} />
          {/* Experiences Page  */}
          <Route path={paths.iskustva} element={<Experiences />} />
          {/* Recension Page  */}
          <Route path={paths.recenzije} element={<Recensions />} />
          {/* Recension Details Page  */}
          <Route
            path={paths.recenzijeDetaljno}
            element={<RecensionDetails />}
          />
          {/* Skin Page  */}
          <Route path={paths.koza} element={<Skin />} />
          {/* Hair Page  */}
          <Route path={paths.kosa} element={<Hair />} />
          {/* News Page  */}
          <Route path={paths.novo} element={<News />} />
          {/* Details Page  */}
          <Route path={paths.details} element={<Details />} />
          {/* Impressum Page  */}
          <Route path={paths.impressum} element={<Impressum />} />
          {/* Not Found Page */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </MainSection>
      <Footer />
    </Router>
  );
}
