import React, { CSSProperties } from "react";

import styled from "styled-components";

interface ParagraphProps {
  px?: string;
  py?: string;
  mx?: string;
  my?: string;
  children: React.ReactChild;
  fontSizeMobile?: string;
  fontSizeDesktop?: string;
  style?: CSSProperties;
  fontColor?: string;
}

interface StyledPProps {
  px?: string;
  py?: string;
  mx?: string;
  my?: string;
  fontSizeMobile?: string;
  fontSizeDesktop?: string;
  fontColor?: string;
}

const StyledP = styled.p<StyledPProps>`
  @media screen and (max-width: ${(props) => props.theme.md}) {
    font-size: ${(props) =>
      props.fontSizeMobile ? props.fontSizeMobile : "1rem"};
  }

  @media screen and (min-width: ${(props) => props.theme.md}) {
    font-size: ${(props) =>
      props.fontSizeMobile ? props.fontSizeMobile : "1.20rem"};
  }

  color: ${(props) => props.fontColor};
`;

export default function Paragraph(props: ParagraphProps) {
  return (
    <StyledP
      style={props.style}
      px={props.px}
      py={props.py}
      mx={props.mx}
      my={props.my}
      fontSizeMobile={props.fontSizeMobile}
      fontSizeDesktop={props.fontSizeDesktop}
      fontColor={props.fontColor}
    >
      {props.children}
    </StyledP>
  );
}
