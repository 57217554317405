import akne from "../../assets/img/GreyImgs/AKNE OŽILJCI PIH PIE.jpg";
import atopijsski from "../../assets/img/GreyImgs/ATOPIJSSKI NEURO I KONTAKTNI DERMATITIS.jpg";
import bore from "../../assets/img/GreyImgs/BORE PORE I OSTALE MORE.jpg";
import njega from "../../assets/img/GreyImgs/NJEGA TIJELA KOŽE KOSE.jpg";
import normalna from "../../assets/img/GreyImgs/NORMALNA KOŽA MJEŠOVITA KOŽA.jpg";
import pocetna from "../../assets/img/GreyImgs/POČETNA.jpg";
import pocetna2 from "../../assets/img/GreyImgs/POČETNA 2.jpg";
import rozacea from "../../assets/img/GreyImgs/ROZACEA KUPEROZA CRVENILO OSJETLJIVOST.jpg";
import suha from "../../assets/img/GreyImgs/SUHA KOŽA  STARENJE.jpg";
import zavrsna from "../../assets/img/GreyImgs/ZDRAVA KOŽA završna slika 1.jpg";
import zavrsna2 from "../../assets/img/GreyImgs/NE LUTAJ i nakon nje ubaci ovu s logom njegaiti završna 2.jpg";
import zavrsna3 from "../../assets/img/GreyImgs/SAMO UBACI MOJ LOGO NA SLIKU NJEGAITI I OVU STAVI NAKON OVE ''SVE NA JEDNOM MJESTU'' završna 3.jpg";

export const CarouselText = [
  {
    text: "",
    image: pocetna,
  },
  {
    text: "",
    image: pocetna2,
  },
  {
    text: "AKNE, OŽILJCI, PIH PIE",
    image: akne,
  },
  {
    text: "ATOPIJSKI, NEURO I KONTAKTNI DERMATITIS",
    image: atopijsski,
  },
  {
    text: "BORE, PORE I OSTALE MORE",
    image: bore,
  },
  {
    text: "NJEGA TIJELA, KOŽE, KOSE",
    image: njega,
  },
  {
    text: "NORMALNA KOŽA, MJEŠOVITA KOŽA",
    image: normalna,
  },
  {
    text: "ROZACEA, KUPEROZA, CRVENILO OSJETLJIVOST",
    image: rozacea,
  },
  {
    text: "SUHA KOŽA, STARENJE",
    image: suha,
  },
  {
    text: "ZDRAVA KOŽA",
    image: zavrsna,
  },
  {
    text: "NE LUTAJ",
    image: zavrsna2,
  },
  {
    text: "SVE NA JEDNOM MJESTU",
    image: zavrsna3,
  },
];
