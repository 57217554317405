import React from "react";

export const ConsultationText = {
  title: "KONSULTACIJE",
  description: () => <React.Fragment>Description TBD</React.Fragment>,
  boxTop: () => <>Izgradi svoju personaliziranu skincare rutinu</>,
  boxBottom: "Riješi svoj problem",
  bubbleTop: "Kako?",
  bubbleBottom: "Kada?",
  button: "Rezerviši svoj termin",
};
