// theme.ts

import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    // Define colors
    anthracitColor: string;
    lithiumLowColor: string;
    lithiumHighColor: string;
    dullColor: string;
    goldLightColor: string;
    goldMediumColor: string;
    goldDarkColor: string;
    colormindYellow: string;
    bezv1: string;
    // Define display sizes
    xs: string;
    sm: string;
    md: string;
  }
}

export const theme: DefaultTheme = {
  anthracitColor: "#222",
  lithiumHighColor: "#6D6027",
  lithiumLowColor: "#d3cbb8",
  dullColor: "#C9D6FF",
  goldLightColor: "#DBB12A",
  goldMediumColor: "#AE8B0F",
  goldDarkColor: "#734B1A",
  colormindYellow: "#EBC665",
  bezv1: "#F9CAA7",
  xs: "768px",
  sm: "992px",
  md: "1200px",
};
