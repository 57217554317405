import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineMail,
} from "react-icons/ai";
import { Container as BSContainer, Col, Image, Row } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

import { ContactText } from "./Contact.resources";
import Container from "../../components/Container/Container";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Paragraph from "../../components/Paragraph/Paragraph";
import React from "react";
import { fadeInUp } from "react-animations";
import img from "../../assets/img/GreyImgs/KONTAKT.jpg";

const fadeInUpItem = keyframes`${fadeInUp}`;

const StyledImg = styled(Image)`
  @media screen and (min-width: ${(props) => props.theme.md}) {
    animation: 2s ${fadeInUpItem};
  }
`;

const StyledLink = styled.a`
  color: black !important;
`;

export default function Contact() {
  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="fluid">
        <BSContainer className="pt-2">
          <Row className={"px-0 mx-0 my-0 py-4 text-center align-items-center"}>
            <h1>{ContactText.title}</h1>
            <h3>{ContactText.description()}</h3>
          </Row>
          <Row
            className={
              "px-0 mx-0 py-2 px-0 mx-0 my-0  text-center align-items-center"
            }
          >
            <Col xs={12} md={12} className="py-2">
              <Paragraph>
                <React.Fragment>
                  <AiOutlineMail size={"1.3rem"} /> {ContactText.email}
                </React.Fragment>
              </Paragraph>
              <Paragraph>
                <React.Fragment>
                  <AiOutlineInstagram size={"1.3rem"} />{" "}
                  <StyledLink
                    href="https://www.instagram.com/njegaiti"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ContactText.insta}
                  </StyledLink>
                </React.Fragment>
              </Paragraph>
              <Paragraph>
                <React.Fragment>
                  <AiOutlineFacebook size={"1.3rem"} />{" "}
                  <StyledLink
                    href="https://www.facebook.com/njegaitibybenjamin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {ContactText.facebook}
                  </StyledLink>
                </React.Fragment>
              </Paragraph>
            </Col>
            <Col
              xs={12}
              md={12}
              className={"px-0 d-flex"}
              style={{ justifyContent: "center" }}
            >
              <StyledImg
                style={{
                  maxWidth: "100%",
                  maxHeight: "800px",
                }}
                src={img}
              />
            </Col>
          </Row>
        </BSContainer>
      </Container>
    </React.Fragment>
  );
}
