import {
  DesktopNavLink,
  HiddenLogo,
  Logo,
  LogoDiv,
  MobileNavLink,
  StyledNavDropdown,
  Styles,
} from "./NavigationBar.styles";
import { Nav, NavDropdown, Navbar } from "react-bootstrap";
import { pathNames, paths } from "../../App/AppRouter/AppRouter.resources";

import { AiOutlineInstagram } from "react-icons/ai";
import React from "react";
import logo from "../../assets/img/logo2.png";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StyledNavbar = styled(Navbar)`
  background-color: ${(props) => props.theme.anthracitColor};
`;

export default function NavigationBar() {
  const navigate = useNavigate();

  const handleClick = (link: string) => {
    navigate(link);
  };

  return (
    <Styles>
      <StyledNavbar collapseOnSelect expand="lg" variant="dark">
        <Navbar.Brand>
          <LogoDiv>
            <Logo src={logo} alt="Logo" />
          </LogoDiv>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-center"
        >
          <Nav>
            <Nav.Link onClick={() => handleClick(paths.home)}>
              NJEGAITI
            </Nav.Link>
            <Nav.Link onClick={() => handleClick(paths.omeni)}>
              {pathNames.omeni}
            </Nav.Link>
            <Nav.Link onClick={() => handleClick(paths.iskustva)}>
              {pathNames.iskustva}
            </Nav.Link>
            <Nav.Link onClick={() => handleClick(paths.konsultacije)}>
              {pathNames.konsultacije}
            </Nav.Link>
            {/* Dropdown items on desktop */}
            <StyledNavDropdown title="" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleClick(paths.kontakt)}>
                {pathNames.kontakt}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick(paths.recenzije)}>
                {pathNames.recenzije}
              </NavDropdown.Item>
              {/* <NavDropdown.Item onClick={() => handleClick(paths.koza)}>
                {pathNames.koza}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleClick(paths.kosa)}>
                {pathNames.kosa}
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item onClick={() => handleClick(paths.novo)}>
                {pathNames.novo}
              </NavDropdown.Item> */}
            </StyledNavDropdown>
            {/* Dropdown items on mobile */}
            <MobileNavLink onClick={() => handleClick(paths.kontakt)}>
              {pathNames.kontakt}
            </MobileNavLink>
            <MobileNavLink onClick={() => handleClick(paths.recenzije)}>
              {pathNames.recenzije}
            </MobileNavLink>
            {/* <MobileNavLink onClick={() => handleClick(paths.koza)}>
              {pathNames.koza}
            </MobileNavLink>
            <MobileNavLink onClick={() => handleClick(paths.kosa)}>
              {pathNames.kosa}
            </MobileNavLink> */}
            {/* <MobileNavLink onClick={() => handleClick(paths.novo)}>
              {pathNames.novo}
            </MobileNavLink> */}
            <MobileNavLink
              href={"https://www.instagram.com/njegaiti/"}
              target={"_blank"}
            >
              <AiOutlineInstagram size="1.5rem" />
            </MobileNavLink>
            <DesktopNavLink
              href={"https://www.instagram.com/njegaiti/"}
              target={"_blank"}
            >
              <AiOutlineInstagram size="1.5rem" />
            </DesktopNavLink>
          </Nav>
        </Navbar.Collapse>
        <HiddenLogo onClick={() => handleClick(paths.home)}>
          <LogoDiv onClick={() => handleClick(paths.home)}>
            <div id="img-logo">
              <Logo src={logo} alt="Logo" />
            </div>
          </LogoDiv>
        </HiddenLogo>
      </StyledNavbar>
    </Styles>
  );
}
