import React, { CSSProperties } from "react";

import { Button as BSButton } from "react-bootstrap";
import styled from "styled-components";

interface ButtonProps {
  text: string;
  style?: CSSProperties;
  className?: string;
  onClick: () => void;
  fontSize?: string;
}

interface StyledButtonProps {
  fontSize?: string;
}

const StyledButton = styled(BSButton)<StyledButtonProps>`
  background-color: ${(props) => props.theme.anthracitColor};
  box-shadow: none !important;
  border-color: ${(props) => props.theme.anthracitColor} !important;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "1.15rem")};
  :hover,
  :active,
  ::selection,
  :focus {
    background-color: ${(props) => props.theme.anthracitColor} !important;
    color: ${(props) => props.theme.dullColor} !important;
  }
`;

export default function Button(props: ButtonProps) {
  return (
    <StyledButton
      fontSize={props.fontSize}
      className={props.className}
      style={props.style}
      onClick={props.onClick}
    >
      {props.text}
    </StyledButton>
  );
}
