export const paths = {
  home: "/",
  omeni: "/omeni",
  konsultacije: "/konsultacije",
  kontakt: "/kontakt",
  iskustva: "/iskustva",
  recenzije: "/recenzije",
  recenzijeDetaljno: "/recenzijedetaljno",
  koza: "/koza",
  kosa: "/kosa",
  novo: "/novo",
  details: "/visedetalja",
  impressum: "/impressum",
  notfound: "/notfound",
};

export const pathNames = {
  home: "NJEGAITI",
  omeni: "O MENI",
  konsultacije: "KONSULTACIJE",
  kontakt: "KONTAKT",
  iskustva: "ISKUSTVA",
  recenzije: "RECENZIJE",
  koza: "KOZA",
  kosa: "KOSA",
  novo: "NOVO",
};
