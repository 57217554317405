import DetailsGorsen28042022 from "../../components/Details/DetailsGorsen28042022";
import DetailsHiperpigmentacija28042022 from "../../components/Details/DetailsHiperpigmentacija28042022";
import DetailsOdermedichydrain3 from "../../components/Details/DetailsOdermedichydrain3";
import DetailsOrigins21042022 from "../../components/Details/DetailsOrigins21042022";
import DetailsRilastil13042022 from "../../components/Details/DetailsRilastil13042022";
import Gorsen_28042022 from "../../assets/img/Recenzije/28042022_gorsen/image00001.png";
import Hiperpigmentacija_28042022 from "../../assets/img/Recenzije/28042022_hiperpigmentacija/POČETNA.jpg";
import Odermedichydrain3_13042022 from "../../assets/img/Recenzije/13042022_dermedichydrain3/IMG_0899.jpeg";
import Origins_21042022 from "../../assets/img/Recenzije/21042022_origins/ORIGINS-GinZing-Oil-Free-Gel-Moisturizer-30ml-500x500.jpg";
import React from "react";
import Rilastil_13042022 from "../../assets/img/Recenzije/13042022_rilastil/IMG_1432.jpeg";

export const RecensionsText = {
  title: "RECENZIJE",
  recensions: [
    {
      id: "rilastil_13042022",
      name: "RILASTIL MULTIREPAIR H.A. DETOX SERUM",
      text: "U današnjoj recenziji donosim Vam jedan od savršenih seruma...",
      image: Rilastil_13042022,
      content: () => <DetailsRilastil13042022 />,
    },
    {
      id: "odermedichydrain3",
      name: "O Dermedic Hydrain3",
      text: "Samo ga kupite I probajte, ali kada ga budete nanosili na kožu brojite do 4 i cijela koža postaje mat...",
      image: Odermedichydrain3_13042022,
      content: () => <DetailsOdermedichydrain3 />,
    },
    {
      id: "origins_21042022",
      name: "Buđenje uz blistavu kožu Origins just do it",
      text: "Buđenje za umornu kožu! – Ova unaprijeđena lagana formula bez ulja u gel formulaciji...",
      image: Origins_21042022,
      content: () => <DetailsOrigins21042022 />,
    },
    {
      id: "gorsen_28042022",
      name: "Gorsen anti akne krema",
      text: "Drago mi je što sam prvi u BiH progovorio o brendu koji danas zauzima 90% polica naše skincare rutine...",
      image: Gorsen_28042022,
      content: () => <DetailsGorsen28042022 />,
    },
    {
      id: "hiperpigmentacija_28042022",
      name: "HIPERPIGMENTACIJA I SUNCE",
      text: "Sigurno ste se nekada susreli sa problemom hiperpigmentacije ili ...",
      image: Hiperpigmentacija_28042022,
      content: () => <DetailsHiperpigmentacija28042022 />,
    },
  ],
  button: "Saznajte više",
};
