import { Container as BSContainer } from "react-bootstrap";
import Container from "../../components/Container/Container";
import NavigationBar from "../../components/NavigationBar/NavigationBar";
import Paragraph from "../../components/Paragraph/Paragraph";
import React from "react";

export default function Impressum() {
  return (
    <React.Fragment>
      <NavigationBar />
      <Container className="fluid" style={{ height: "100%" }}>
        <BSContainer className="pt-2">
          <h1>KORIŠTENJE STRANICE</h1>
          <Paragraph>
            <>
              Korisnici samovoljno, za svoju potrebu i na svoju odgovornost
              prihvataju, čitanje, informisanje I korištenje podataka sa
              stranice.
            </>
          </Paragraph>
          <Paragraph>
            <>
              Zabranjeno je svako preuzimanje sadržaja sa stranice
              www.njegaiti.com u vlasništvu (Benjamin Mrkonjić) i plasiranje na
              druge društvene mreže, časopise, knjige, literature i slično, bez
              prethodnog obavještenja I upita. Svaka zloupotreba podataka i
              sadržaja biti će krivično terećena gdje će isto biti smatrano kao
              ‘’krađa’’ I biti će upućeno ka sudskim organima kao i korištenje
              specijalizovanih programa (robota, crawlera) radi preuzimanja
              sadržaja sa stranice. Hakerski napadi s ciljem onesposobljavanja
              stranice također su strogo zabranjeni.
            </>
          </Paragraph>
          <h2>AUTORSKA PRAVA</h2>
          <Paragraph>
            Benjamin Mrkonjić je nositelj autorskih prava na svim vlastitim
            sadržajima. Neovlašteno korištenje bilo kojeg sadržaja stranice bez
            dozvole vlasnika autorskih prava smatra se kršenjem autorskih prava
            i podložno je tužbi.
          </Paragraph>
          <Paragraph>
            Benjamin Mrkonjić je nositelj autorskih prava na svim vlastitim
            sadržajima. Neovlašteno korištenje bilo kojeg sadržaja stranice bez
            dozvole vlasnika autorskih prava smatra se kršenjem autorskih prava
            i podložno je tužbi.
          </Paragraph>
          <h2>OPĆI UVJETI</h2>
          <Paragraph>
            Ove opće odredbe i uvjeti definiraju korištenje web stranice
            “njegaiti” koje prihvaćate prilikom posjećivanja stranice. Ako ne
            prihvaćate ove odredbe i uvjete, molimo napustite stranicu. Ako
            niste punoljetni, ovime se obvezujete da ćete ovu stranicu
            posjećivati isključivo u nazočnosti svojih roditelja ili zakonskog
            skrbnika, koji prvo moraju prihvatiti postojeće odredbe i uvjete.
            Postojeće odredbe i uvjeti mogu se mijenjati u bilo kojem trenutku
            bez prethodne najave.
          </Paragraph>
          <Paragraph>
            Vlasnik stranice se obavezuje da će iznositi samo istinite I tačne
            informacije na svojoj stranici. Ni u jednom trenutku istu neće
            zloupotrijebiti za određeni vid krađe, narušavanja identiteta ili
            dostavljanja I prezentiranja lažnih I netačnih informacija.
          </Paragraph>
          <h2>ISKLJUČENJE ODGOVORNOSTI</h2>
          <Paragraph>
            Internet Stranicu ‘’www.njegaiti.com’’ koristite na sopstveni rizik.
            Autor, nije odgovoran za materijalnu ili nematerijalnu štetu,
            direktnu ili indirektnu koja nastane iz korišćenja ili je u nekoj
            vezi sa korištenjem internet stranice ili njenog sadržaja.
          </Paragraph>
          <h2>IZMJENA USLOVA KORIŠTENJA</h2>
          <Paragraph>
            Autor može u bilo kojem trenutku izmijeniti ove uslove korišćenja
            tako što će ažurirati ovaj tekst I samu stranicu. Vi ćete automatski
            biti obavezani novim uslovima korištenja sadržanim u izmjenama, te
            bi iz tog razloga trebalo da periodično posjetite ovu stranicu radi
            upoznavanja sa trenutno važećim uslovima korištenja. Sve buduće
            izmjene smatraće se prihavćenim od strane korisnika internet
            stranice.
          </Paragraph>
          <h2>KORIŠTENJE KOLAČIĆA (COOKIES)</h2>
          <Paragraph>
            Stranica www.njegaiti.com neće koristiti ‘’cookies-e’’ niti
            prikupljati bilo koje Vaše informacije.
          </Paragraph>
          <h2>ČUVANJE PRIVATNOSTI PODATAKA</h2>
          <Paragraph>
            Svi eventualni mogući potrebni podaci o korisnicima i mogućim
            potencijalnim saradnicima marketinškim kućama I aktivnostima u
            budućnosti, biti će strogo čuvani i dostupni samo isključivo
            vlasniku stranice. Poslovni partneri i ja smo odgovorni za
            poštivanje pravila o zaštiti privatnosti.
          </Paragraph>
          <h2>KONTAKT INFORMACIJE</h2>
          <Paragraph>
            <>
              <b>EMAIL:</b> njegaiti@gmail.com
            </>
          </Paragraph>
          <Paragraph>
            <>
              <b>INSTAGRAM:</b> @njegaiti
            </>
          </Paragraph>
          <Paragraph>
            <>
              <b>BROJ TELEFONA:</b> Na upit
            </>
          </Paragraph>
          <h2>GLAVNI I ODGOVORNI UREDNIK STRANICE</h2>
          <Paragraph>Benjamin Mrkonjić</Paragraph>
          <h2>FOTOGRAFIJE</h2>
          <Paragraph>Benjamin Mrkonjić</Paragraph>
          <Paragraph>Saša Raičević</Paragraph>
          <Paragraph>Ema Milinović</Paragraph>
          <h2>MODERATOR STRANICE</h2>
          <Paragraph>Benjamin Mrkonjić</Paragraph>
          <Paragraph>Nedim Rifatbegović</Paragraph>
        </BSContainer>
      </Container>
    </React.Fragment>
  );
}
