import Paragraph from "../../components/Paragraph/Paragraph";
import React from "react";

export const text = {
  aboutMe: "MOJ CILJ",
  aboutMeDescription: () => (
    <React.Fragment>
      <Paragraph fontSizeMobile="1.3rem">
        Pozdrav tebi draga osobo koja čitaš i gledaš ovu stranicu. Od srca želim
        dobrodošlicu.
      </Paragraph>
      <Paragraph fontSizeMobile="1.3rem">
        Moj cilj i misija pokretanja cijele ove priče jeste vrlo jednostavna:
        zdrava i njegovana koža uz stručnu i brižnu pomoć. Želim da svako od Vas
        ima isto i da zajedno pravimo revoluciju u skin care svijetu uz pametno
        investiranje novca u proizvode koji Vam zaista i od srca iskreno
        trebaju.
      </Paragraph>
    </React.Fragment>
  ),
  aboutMeButton: "Saznajte više",
};
