import { Image } from "react-bootstrap";
import Paragraph from "../Paragraph/Paragraph";
import React from "react";
import img1 from "../../assets/img/Recenzije/28042022_hiperpigmentacija/POČETNA.jpg";

export default function DetailsHiperpigmentacija28042022() {
  return (
    <React.Fragment>
      <h3>HIPERPIGMENTACIJA I SUNCE</h3>
      <Paragraph>
        Sigurno ste se nekada susreli sa problemom hiperpigmentacije ili osobama
        koje imaju problema sa istom?
      </Paragraph>
      <Paragraph>
        Hiperpigmentacija predstavlja jedan od najpoželjnijih promjena na koži
        koje vole sunce. Zašto?
      </Paragraph>
      <Paragraph>
        Kada Sunčeve UVA i UVB zrake dođu u dodir sa već nastalom ili kožom i
        mjestima kože koji su pogodni za nastajanje hiperpigmentacije, dolazi do
        sinergije odnosno štetne zrake UV spektra dovode do širenje te iste
        hiperpigmentacije.
      </Paragraph>
      <Paragraph>Šta je to zapravo hiperpigmentacija?</Paragraph>
      <Paragraph>
        Hiperpigmentacija ili u narodu poznata kao ''flekice'' čest je problem s
        kojim se svakodnevno bori većina ljudi. Hiperpigmentacija se pojavljuje
        i očituje u obliku tamnih mrlja na koži. Osobe sa tamnom kožom su više
        skloni ka nastanku iste. Hiperpigmentacija je još poznata kao i melazma,
        odnosno hiperpigmentacija u globalu nastaje zbog prekromjerne
        proizvodnje melanina. Melanin je prirodni pigment koji našoj koži, kosi
        i očima daje njihovu prirodnu boju. Postoje razni faktori zbog povećane
        proizvodnje melanina ali se najvažniji veže za izlaganje suncu bez
        adekvatne SPF+ zaštite, genetika, trudnoća te sami hormonalni disbalans.
      </Paragraph>
      <Image
        style={{
          maxWidth: "100%",
          maxHeight: "400px",
        }}
        className={"mb-2"}
        src={img1}
      />
      <Paragraph>
        Ukoliko imate problema sa hiperpigmentacijom, dajem Vam nekoliko
        savjeta:
      </Paragraph>
      <Paragraph>
        1. Obavezno koristite kreme sa zaštitnim faktorom i kreme koje će ujedno
        djelovati na nastalu hiperpigmentaciju ali i spriječiti širenje i
        nastanak novih ''tamnih mrlja''.
      </Paragraph>
      <Paragraph>
        2.Koristite proizvode u večernjoj rutini njege lica na bazi
        Alfa-hidroksi kiselina koje će djelovati protiv hiperpigmentacije i svih
        nepravilnosti na koži lica.
      </Paragraph>
      <Paragraph>
        3. Ograničite izlaganje suncu u najjačim intervalima proizvodnje samih
        štetnih UVA/UVB zraka.
      </Paragraph>
      <Paragraph>
        4. Birajte SPF+ kreme koje sadrže fizičke filtere kao što je Zn-Oksid.
      </Paragraph>
      <Paragraph>Koje proizvode da koristim?</Paragraph>
      <Paragraph>
        Saznaj više u budućim recenzijama i na instagram profilu.
      </Paragraph>
    </React.Fragment>
  );
}
